// Credits: https://github.com/mauricevancooten/react-anchor-link-smooth-scroll
// Forked to add `afterScroll` callback with Promise to resolve when scrolling is finished.
// Also refactored code, added types, and improved lifecycle to clean up component when unmounted.

import React from 'react'
import SmoothScroll from 'smoothscroll-polyfill'

interface Props {
  href: string
  className?: string
  offset?: (() => number) | string | number
  afterScroll?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

interface AnchorLink {
  timeoutHandle: number
}

class AnchorLink extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.scrollOnClick = this.scrollOnClick.bind(this)
    this.smoothScroll = this.smoothScroll.bind(this)
    this.scrollEndHandler = this.scrollEndHandler.bind(this)
    this.setScrollEndHandler = this.setScrollEndHandler.bind(this)
    this.setTimeoutHandle = this.setTimeoutHandle.bind(this)
    this.getTimeoutHandle = this.getTimeoutHandle.bind(this)
  }

  // placeholder declared for binding
  scrollEndHandler(_e: Event) {}

  setScrollEndHandler(scrollEndHandler: EventListener) {
    this.scrollEndHandler = scrollEndHandler
  }

  setTimeoutHandle(timeout: number) {
    this.timeoutHandle = timeout
  }

  getTimeoutHandle() {
    return this.timeoutHandle
  }

  componentDidMount() {
    SmoothScroll.polyfill()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollEndHandler)
    window.clearTimeout(this.getTimeoutHandle())
  }

  scrollOnClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault()
    const { offset } = this.props
    let offsetCb = () => 0
    if (typeof offset !== 'undefined') {
      if (typeof offset === 'function') {
        offsetCb = offset
      } else if (typeof offset === 'string') {
        offsetCb = () => parseInt(offset)
      } else {
        offsetCb = () => offset
      }
    }

    const path = e.currentTarget.getAttribute('href')
    if (path) {
      const id = path.slice(1)
      const $anchor = document.getElementById(id)

      if ($anchor) {
        this.smoothScroll($anchor, offsetCb())
          .then(() => {
            if (this.props.afterScroll) {
              this.props.afterScroll(e)
            }
          })
          .catch(() => {
            window.removeEventListener('scroll', this.scrollEndHandler)
          })
      } else {
        console.warn(`No anchor section match the ID: ${id}`)
      }
    }
    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  smoothScroll(elem: HTMLElement, offset = 0) {
    const rect = elem.getBoundingClientRect()
    const targetPosition = rect.top + window.pageYOffset - offset
    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    })

    return new Promise((resolve, reject) => {
      this.setScrollEndHandler(() => {
        // Clear timeout throughout the scroll
        window.clearTimeout(this.getTimeoutHandle())

        const scrollEndHandler = this.scrollEndHandler

        // Set a timeout to run after scrolling ends
        this.setTimeoutHandle(
          window.setTimeout(function () {
            // Scrolling has stopped
            window.removeEventListener('scroll', scrollEndHandler)
            resolve()
          }, 66)
        )
      })

      window.addEventListener('scroll', this.scrollEndHandler)
    })
  }

  render() {
    const { offset, afterScroll, ...rest } = this.props
    return <a {...rest} onClick={this.scrollOnClick} />
  }
}

export default AnchorLink
