export const TechDocsUrlPrefixes = {
  CaseStudy: 'customer-stories',
  Faq: 'faqs',
  TutorialVideo: 'video-tutorials',
  TechnicalDocument: {
    how_to_guide: 'guides',
    user_manual: 'guides',
    datasheet: 'datasheets',
    design_guide: 'guides',
    maintenance_guide: 'guides',
    application_guide: 'guides',
  },
}

export const TechDocsTypeNameEscaped = {
  CaseStudy: 'Case Study',
  Faq: 'FAQ',
  TutorialVideo: 'Tutorial Video',
  TechnicalDocument: {
    how_to_guide: 'How to guide',
    user_manual: 'User manual',
    datasheet: 'Datasheet',
    design_guide: 'Design guide',
    maintenance_guide: 'Maintenance guide',
    application_guide: 'Application guide',
  },
}
