import React from 'react'

interface Props {
  readonly imgLarge?: string
  readonly imgMedium?: string
  readonly imgDefault?: string
  readonly alt?: string
  readonly style?: React.CSSProperties
}

const DynamicImageComponent: React.FunctionComponent<Props> = ({
  imgLarge,
  imgMedium,
  imgDefault,
  alt,
  style,
}) => {
  return (
    <div className='dynamic-img' style={style}>
      <picture>
        {imgLarge && <source media='(min-width: 993px)' srcSet={imgLarge} />}
        {imgMedium && <source media='(max-width: 992px)' srcSet={imgMedium} />}
        <img src={imgDefault} alt={alt} />
      </picture>
    </div>
  )
}

export default DynamicImageComponent
