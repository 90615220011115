import React from 'react'
import {
  CallToAction,
  CallToActionDefinition,
  CallToActionDefinitions,
  CTA_BUTTON_CLASS_NAMES,
} from '../../api/Types'
import StartBuildingButton from '../../../assembly_card/StartBuildingButton'
import classNames from 'classnames'
import { LocalizedPaths } from '../../../../types/Common'

interface Props {
  readonly cta: CallToAction
  readonly newTab?: boolean
  readonly localizedPaths?: LocalizedPaths
  analyticIdPrefix?: string
}

const DynamicCallToAction: React.FunctionComponent<Props> = ({
  cta,
  newTab,
  localizedPaths,
  analyticIdPrefix,
}) => {
  let ctaContent: React.ReactNode = null

  if ('predefinedType' in cta) {
    if (cta.predefinedType in CallToActionDefinitions) {
      const newCta = CallToActionDefinition(cta)
      return DynamicCallToAction({ cta: newCta, newTab, localizedPaths })
    } else {
      return null
    }
  }

  const url = cta.urlLocaleKey && localizedPaths ? localizedPaths[cta.urlLocaleKey] : cta.url

  if (cta.label && !cta.color) {
    // if no color is specified, we want to display a link...
    if (url) {
      // returns a link opening a given url
      ctaContent = (
        <a
          href={url}
          {...(newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
          data-analytic-id={analyticIdPrefix ? `${analyticIdPrefix}-link` : undefined}
        >
          {cta.label}
        </a>
      )
    } else {
      // returns a link opening up a modal if no url was given
      ctaContent = (
        <StartBuildingButton
          label={cta.label}
          className='button-lg'
          anchorTag
          analyticId={analyticIdPrefix ? `${analyticIdPrefix}-button` : undefined}
        />
      )
    }
  } else if (cta.label) {
    // if color exists, we want to display a button...
    if (!url) {
      // returns a button opening a modal if no url given
      ctaContent = (
        <StartBuildingButton
          label={cta.label}
          className={`button-lg button ${
            cta.color ? CTA_BUTTON_CLASS_NAMES[cta.color] : 'button-vention'
          }`}
          analyticId={analyticIdPrefix ? `${analyticIdPrefix}-button` : undefined}
        />
      )
    } else {
      const classes = classNames(
        `button-lg button ${cta.color ? CTA_BUTTON_CLASS_NAMES[cta.color] : 'button-vention'}`,
        {
          'button-with-icon': !!cta.leftIcon,
        }
      )
      // returns a button acting as a link given a url
      ctaContent = (
        <a
          href={url}
          {...(newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
          className={classes}
          data-analytic-id={analyticIdPrefix ? `${analyticIdPrefix}-link` : undefined}
        >
          {cta.leftIcon && <i className={classNames('left-icon', cta.leftIcon)}></i>}
          {cta.label}
        </a>
      )
    }
  }

  if (!ctaContent) {
    return null // when no label is given
  }

  return <div className={`lp-dynamic-cta ${!cta.color ? 'cta-link' : ''}`}>{ctaContent}</div>
}

export default DynamicCallToAction
